.create-post-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    background-color: var(--dl-color-gray-pagebackground);
}

.create-post-management-type-container {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    margin-top: var(--dl-space-space-twounits);
    align-items: center;
    margin-bottom: var(--dl-space-space-twounits);
    flex-direction: column;
}

.create-post-manage-heading {
    color: var(--dl-color-gray-white);
}

.create-post-container1 {
    flex: 0 0 auto;
    width: auto;
    display: flex;
    margin-top: var(--dl-space-space-halfunit);
    align-items: flex-start;
    margin-left: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-halfunit);
    flex-direction: row;
}

.create-post-manage-type-create {
    color: var(--dl-color-gray-white);
    font-style: normal;
    font-weight: 600;
}

.create-post-create-radio {
    margin-top: 3px;
    margin-left: 5px;
}

.create-post-manage-type-delete {
    color: var(--dl-color-gray-white);
    font-style: normal;
    font-weight: 600;
    margin-left: var(--dl-space-space-halfunit);
}

.create-post-delete-radio {
    margin-top: 3px;
    margin-left: 5px;
}

.create-post-delete-posts {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.create-post-delete-bot {
    flex: 0 0 auto;
    width: auto;
    display: flex;
    align-items: center;
    margin-right: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: center;
}

.create-post-form {
    width: auto;
    height: auto;
    display: flex;
    border-radius: var(--dl-radius-radius-radius8);
    background-image: radial-gradient(circle at right top, rgb(94, 23, 235) 0.00%, rgb(56, 182, 255) 99.00%);
}

.create-post-container2 {
    flex: 0 0 auto;
    display: flex;
    align-self: center;
    align-items: flex-start;
    flex-direction: column;
}

.create-post-text06 {
    color: var(--dl-color-gray-white);
    align-self: center;
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.create-post-fname {
    color: var(--dl-color-gray-white);
    font-style: normal;
    font-weight: 600;
    margin-left: var(--dl-space-space-unit);
}

.create-post-fname-input {
    align-self: center;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
}

.create-post-fname1 {
    color: var(--dl-color-gray-white);
    font-style: normal;
    font-weight: 600;
    margin-left: var(--dl-space-space-unit);
}

.create-post-fname-input1 {
    height: var(--dl-size-size-xlarge);
    align-self: center;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
}

.create-post-signup {
    color: var(--dl-color-gray-white);
    align-self: center;
    border-width: 0px;
    margin-bottom: var(--dl-space-space-unit);
    background-color: var(--dl-color-primary-daikipurple);
}

.create-post-delete-week {
    flex: 0 0 auto;
    width: auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.create-post-form1 {
    width: auto;
    height: auto;
    display: flex;
    border-radius: var(--dl-radius-radius-radius8);
    background-image: radial-gradient(circle at right top, rgb(94, 23, 235) 0.00%, rgb(56, 182, 255) 99.00%);
}

.create-post-container3 {
    flex: 0 0 auto;
    display: flex;
    align-self: center;
    align-items: flex-start;
    flex-direction: column;
}

.create-post-text16 {
    color: var(--dl-color-gray-white);
    align-self: center;
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.create-post-fname2 {
    color: var(--dl-color-gray-white);
    font-style: normal;
    font-weight: 600;
    margin-left: var(--dl-space-space-unit);
}

.create-post-fname-input2 {
    align-self: center;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
}

.create-post-fname3 {
    color: var(--dl-color-gray-white);
    font-style: normal;
    font-weight: 600;
    margin-left: var(--dl-space-space-unit);
}

.create-post-fname-input3 {
    height: var(--dl-size-size-xlarge);
    align-self: center;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
}

.create-post-signup1 {
    color: var(--dl-color-gray-white);
    align-self: center;
    border-width: 0px;
    margin-bottom: var(--dl-space-space-unit);
    background-color: var(--dl-color-primary-daikipurple);
}

.create-post-delete-posts1 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.create-post-delete-bot1 {
    flex: 0 0 auto;
    width: auto;
    display: flex;
    align-items: center;
    margin-right: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: center;
}

.create-post-form2 {
    width: auto;
    height: auto;
    display: flex;
    border-radius: var(--dl-radius-radius-radius8);
    background-image: radial-gradient(circle at right top, rgb(94, 23, 235) 0.00%, rgb(56, 182, 255) 99.00%);
}

.create-post-container4 {
    flex: 0 0 auto;
    display: flex;
    align-self: center;
    align-items: flex-start;
    flex-direction: column;
}

.create-post-text26 {
    color: var(--dl-color-gray-white);
    align-self: center;
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.create-post-fname4 {
    color: var(--dl-color-gray-white);
    font-style: normal;
    font-weight: 600;
    margin-left: var(--dl-space-space-unit);
}

.create-post-fname-input4 {
    align-self: center;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
}

.create-post-signup2 {
    color: var(--dl-color-gray-white);
    align-self: center;
    border-width: 0px;
    margin-bottom: var(--dl-space-space-unit);
    background-color: var(--dl-color-primary-daikipurple);
}

.create-post-delete-week1 {
    flex: 0 0 auto;
    width: auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.create-post-form3 {
    width: auto;
    height: auto;
    display: flex;
    border-radius: var(--dl-radius-radius-radius8);
    background-image: radial-gradient(circle at right top, rgb(94, 23, 235) 0.00%, rgb(56, 182, 255) 99.00%);
}

.create-post-container5 {
    flex: 0 0 auto;
    display: flex;
    align-self: center;
    align-items: flex-start;
    flex-direction: column;
}

.create-post-text34 {
    color: var(--dl-color-gray-white);
    align-self: center;
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.create-post-fname5 {
    color: var(--dl-color-gray-white);
    font-style: normal;
    font-weight: 600;
    margin-left: var(--dl-space-space-unit);
}

.create-post-fname-input5 {
    align-self: center;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
}

.create-post-signup3 {
    color: var(--dl-color-gray-white);
    align-self: center;
    border-width: 0px;
    margin-bottom: var(--dl-space-space-unit);
    background-color: var(--dl-color-primary-daikipurple);
}

@media(max-width: 479px) {
    .create-post-delete-posts {
        align-items: center;
        flex-direction: column;
    }

    .create-post-delete-bot {
        margin-right: 0px;
        margin-bottom: var(--dl-space-space-twounits);
    }

    .create-post-delete-week {
        margin-right: 0px;
        margin-bottom: var(--dl-space-space-threeunits);
    }

    .create-post-delete-posts1 {
        align-items: center;
        flex-direction: column;
    }

    .create-post-delete-bot1 {
        margin-right: 0px;
        margin-bottom: var(--dl-space-space-twounits);
    }

    .create-post-delete-week1 {
        margin-right: 0px;
        margin-bottom: var(--dl-space-space-threeunits);
    }
}