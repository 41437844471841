.header-header {
  width: 100%;
  height: var(--dl-size-size-small);
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
  background-color: var(--dl-color-primary-navbackground);
}

.header-image {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
}

.header-nav {
  display: flex;
}

.header-nav1 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-unit);
  flex-direction: row;
}

.header-navlink {
  color: var(--dl-color-gray-white);
  text-decoration: none;
}

.header-nav2 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.header-navlink1 {
  color: var(--dl-color-gray-white);
  text-decoration: none;
}

.header-btn-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.header-login {
  border-width: 0px;
}


@media(max-width: 767px) {
  .header-header {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }

  .header-nav1 {
    align-items: flex-start;
    flex-direction: column;
  }

  .header-navlink {
    margin-bottom: 0px;
  }

  .header-nav2 {
    align-items: flex-start;
    flex-direction: column;
  }

  .header-navlink1 {
    margin-bottom: 0px;
  }
}

@media(max-width: 479px) {
  .header-header {
    padding: var(--dl-space-space-unit);
  }

  .header-navlink {
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }

  .header-navlink1 {
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
}