.login-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-pagebackground);
}
.login-form {
  width: auto;
  height: auto;
  display: flex;
  border-radius: var(--dl-radius-radius-radius8);
  background-image: radial-gradient(circle at right top, rgb(94, 23, 235) 0.00%,rgb(56, 182, 255) 99.00%);
}
.login-container1 {
  flex: 0 0 auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.login-text {
  color: var(--dl-color-gray-white);
  align-self: center;
  margin-top: var(--dl-space-space-oneandhalfunits);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.login-text3 {
  color: var(--dl-color-gray-white);
  font-style: normal;
  font-weight: 600;
  margin-left: var(--dl-space-space-unit);
}
.login-textinput {
  align-self: center;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-unit);
}
.login-text4 {
  color: var(--dl-color-gray-white);
  font-style: normal;
  font-weight: 600;
  margin-left: var(--dl-space-space-unit);
}
.login-textinput1 {
  align-self: center;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
}
.login-button {
  color: var(--dl-color-gray-white);
  align-self: center;
  border-width: 0px;
  margin-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-primary-daikipurple);
}
