.user-home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #171717;
}

.user-home-welcome-container {
  flex: 0 0 auto;
  display: flex;
  align-self: center;
  margin-top: var(--dl-space-space-fourunits);
  align-items: flex-start;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: column;
}

.user-home-text {
  color: var(--dl-color-gray-900);
  font-size: 1.5em;
  align-self: center;
}

.user-home-container1 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-threeunits);
  align-items: flex-start;
  justify-content: center;
}

.user-home-container2 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: flex-start;
  margin-left: var(--dl-space-space-fiveunits);
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-image: radial-gradient(circle at right top, rgb(94, 23, 235) 0.00%, rgb(56, 182, 255) 99.00%);
}

.user-home-text01 {
  color: var(--dl-color-gray-white);
  align-self: center;
}

.user-home-separator {
  flex: 0 0 auto;
  width: 100%;
  height: 0px;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-color: var(--dl-color-gray-white);
  border-style: solid;
  border-width: 1px;
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}

.user-home-text04 {
  color: var(--dl-color-gray-900);
  align-self: center;
  margin-top: var(--dl-space-space-halfunit);
}

.user-home-text07 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-halfunit);
}

.user-home-container3 {
  flex: 0 0 auto;
  width: 650px;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: center;
  margin-left: var(--dl-space-space-fiveunits);
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-image: radial-gradient(circle at left top, rgb(94, 23, 235) 0.00%, rgb(56, 182, 255) 99.00%);
}

.user-home-text08 {
  color: var(--dl-color-gray-white);
  align-self: center;
}

.user-home-separator1 {
  flex: 0 0 auto;
  width: 100%;
  height: 0px;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-color: var(--dl-color-gray-white);
  border-style: solid;
  border-width: 1px;
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}

.user-home-text11 {
  color: var(--dl-color-gray-white);
  line-height: 1.75;
}

.user-home-text12 {
  font-weight: 700;
}

.user-home-text13 {
  font-weight: 700;
}

.user-home-text15 {
  font-weight: 700;
  text-decoration: underline;
}

.user-home-text33 {
  font-weight: 700;
}

.user-home-text34 {
  font-weight: 700;
}

@media(max-width: 991px) {
  .user-home-container1 {
    flex-direction: column;
  }

  .user-home-container2 {
    align-self: center;
    margin-left: 0px;
  }

  .user-home-container3 {
    width: 550px;
    margin-top: var(--dl-space-space-threeunits);
    margin-left: 0px;
  }
}

@media(max-width: 767px) {
  .user-home-container1 {
    flex-direction: column;
  }

  .user-home-container2 {
    align-self: center;
    margin-left: 0px;
  }

  .user-home-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }

  .user-home-container3 {
    width: auto;
    margin-top: var(--dl-space-space-twounits);
    margin-left: 0px;
  }

  .user-home-separator1 {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}

@media(max-width: 479px) {
  .user-home-container1 {
    flex-direction: column;
  }

  .user-home-container2 {
    align-self: center;
    margin-left: 0px;
  }

  .user-home-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }

  .user-home-container3 {
    width: auto;
    margin-top: var(--dl-space-space-twounits);
    margin-left: 0px;
  }

  .user-home-separator1 {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}