.admin-header-admin-header {
    width: 100%;
    height: var(--dl-size-size-small);
    display: flex;
    position: relative;
    align-items: center;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: space-between;
    background-color: var(--dl-color-primary-navbackground);
}

.admin-header-image {
    width: var(--dl-size-size-small);
    height: var(--dl-size-size-small);
}

.admin-header-nav {
    display: flex;
}

.admin-header-nav1 {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.admin-header-navlink {
    color: var(--dl-color-gray-white);
    text-decoration: none;
}

.admin-header-nav2 {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.admin-header-navlink1 {
    color: var(--dl-color-gray-white);
    margin-left: var(--dl-space-space-unit);
    text-decoration: none;
}

.admin-header-nav3 {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.admin-header-navlink2 {
    color: var(--dl-color-gray-white);
    margin-left: var(--dl-space-space-unit);
    text-decoration: none;
}

.admin-header-btn-group {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.admin-header-login {
    border-width: 0px;
}

@media(max-width: 767px) {
    .admin-header-admin-header {
        padding-left: var(--dl-space-space-twounits);
        padding-right: var(--dl-space-space-twounits);
    }

    .admin-header-nav1 {
        align-items: flex-start;
        flex-direction: column;
    }

    .admin-header-navlink {
        margin-bottom: 0px;
    }

    .admin-header-nav2 {
        align-items: flex-start;
        flex-direction: column;
    }

    .admin-header-navlink1 {
        margin-bottom: 0px;
    }

    .admin-header-nav3 {
        align-items: flex-start;
        flex-direction: column;
    }

    .admin-header-navlink2 {
        margin-bottom: 0px;
    }
}

@media(max-width: 479px) {
    .admin-header-admin-header {
        padding: var(--dl-space-space-unit);
    }

    .admin-header-navlink {
        margin-top: var(--dl-space-space-unit);
        margin-bottom: var(--dl-space-space-unit);
    }

    .admin-header-navlink1 {
        margin-top: var(--dl-space-space-unit);
        margin-bottom: var(--dl-space-space-unit);
    }

    .admin-header-navlink2 {
        margin-top: var(--dl-space-space-unit);
        margin-bottom: var(--dl-space-space-unit);
    }
}