.updates-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #272626;
}

.updates-daiki-bot-updates {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  background-color: #272626;
}

.updates-text {
  color: var(--dl-color-gray-white);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.updates-container1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: space-between;
}

.updates-post {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 4px 4px 10px 0px rgba(171, 163, 163, 0.1);
  transition: 0.3s;
  align-items: stretch;
  flex-direction: row;
  justify-content: space-between;
  background-image: radial-gradient(circle at left top, rgb(94, 23, 235) 0.00%, rgb(56, 182, 255) 99.00%);
}

.updates-post:hover {
  transform: scale(1.02);
}

.updates-container2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}

.updates-bot-post-title {
  color: var(--dl-color-gray-white);
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-twounits);
}

.updates-bot-post-description {
  color: #e6e5e5;
  font-size: 1.25rem;
  margin-bottom: var(--dl-space-space-twounits);
}

.updates-container3 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.updates-profile {
  display: flex;
  align-items: center;
}

.updates-bot-post-author {
  color: var(--dl-color-gray-white);
  font-style: normal;
  font-weight: 600;
}

.updates-container4 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.updates-bot-post-id {
  color: var(--dl-color-gray-white);
  align-self: flex-end;
  font-style: normal;
  font-weight: 600;
}

.updates-blog {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  background-color: #272626;
}

.updates-text07 {
  color: var(--dl-color-gray-white);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.updates-container5 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: space-between;
}

.updates-post1 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 4px 4px 10px 0px rgba(171, 163, 163, 0.1);
  transition: 0.3s;
  align-items: stretch;
  flex-direction: row;
  justify-content: space-between;
  background-image: radial-gradient(circle at left top, rgb(94, 23, 235) 0.00%, rgb(56, 182, 255) 99.00%);
}

.updates-post1:hover {
  transform: scale(1.02);
}

.updates-container6 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}

.updates-week-post-title {
  color: var(--dl-color-gray-white);
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-twounits);
}

.updates-week-post-description {
  color: #E6E5E5;
  font-size: 1.25rem;
  margin-bottom: var(--dl-space-space-twounits);
}

.updates-container7 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.updates-profile1 {
  display: flex;
  align-items: center;
}

.updates-week-post-author {
  color: var(--dl-color-gray-white);
  font-style: normal;
  font-weight: 600;
}

.updates-container8 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.updates-bot-post-id1 {
  color: var(--dl-color-gray-white);
  align-self: flex-end;
  font-style: normal;
  font-weight: 600;
}

@media(max-width: 991px) {
  .updates-post {
    flex-direction: row;
    justify-content: space-between;
  }

  .updates-post1 {
    flex-direction: row;
    justify-content: space-between;
  }
}

@media(max-width: 767px) {
  .updates-daiki-bot-updates {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }

  .updates-post {
    flex-direction: column;
  }

  .updates-blog {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }

  .updates-post1 {
    flex-direction: column;
  }
}

@media(max-width: 479px) {
  .updates-daiki-bot-updates {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }

  .updates-bot-post-title {
    color: var(--dl-color-gray-white);
  }

  .updates-bot-post-description {
    color: var(--dl-color-gray-700);
    font-size: 1.25rem;
  }

  .updates-container3 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .updates-bot-post-author {
    color: var(--dl-color-gray-white);
    font-style: normal;
    font-weight: 600;
  }

  .updates-bot-post-id {
    color: var(--dl-color-gray-white);
    font-style: normal;
    font-weight: 600;
  }

  .updates-blog {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }

  .updates-week-post-title {
    color: var(--dl-color-gray-white);
  }

  .updates-week-post-description {
    color: var(--dl-color-gray-700);
    font-size: 1.25rem;
  }

  .updates-container7 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .updates-week-post-author {
    color: var(--dl-color-gray-white);
    font-style: normal;
    font-weight: 600;
  }

  .updates-bot-post-id1 {
    color: var(--dl-color-gray-white);
    font-style: normal;
    font-weight: 600;
  }
}