.signup-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-pagebackground);
}

.signup-form {
  width: auto;
  height: auto;
  display: flex;
  border-radius: var(--dl-radius-radius-radius8);
  background-image: radial-gradient(circle at right top, rgb(94, 23, 235) 0.00%, rgb(56, 182, 255) 99.00%);
}

.signup-container1 {
  flex: 0 0 auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}

.signup-text {
  color: var(--dl-color-gray-white);
  align-self: center;
  margin-top: var(--dl-space-space-oneandhalfunits);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.signup-container2 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}

.signup-registertext {
  color: var(--dl-color-gray-white);
  font-style: normal;
  font-weight: 600;
}

.signup-registertext1 {
  color: var(--dl-color-gray-white);
  font-style: normal;
  font-weight: 600;
  margin-left: var(--dl-space-space-halfunit);
}

.signup-radiobutton {
  margin-top: 3px;
  margin-left: 2px;
}

.signup-registertext2 {
  color: var(--dl-color-gray-white);
  font-style: normal;
  font-weight: 600;
  margin-left: var(--dl-space-space-halfunit);
}

.signup-radiobutton1 {
  margin-top: 3px;
  margin-left: 2px;
}

.signup-uskey {
  color: var(--dl-color-gray-white);
  font-style: normal;
  font-weight: 600;
  margin-left: var(--dl-space-space-unit);
}

.signup-uskey-input {
  align-self: center;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-unit);
}

.signup-askey {
  color: var(--dl-color-gray-white);
  font-style: normal;
  font-weight: 600;
  margin-left: var(--dl-space-space-unit);
}

.signup-askey-input {
  align-self: center;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-unit);
}

.signup-fname {
  color: var(--dl-color-gray-white);
  font-style: normal;
  font-weight: 600;
  margin-left: var(--dl-space-space-unit);
}

.signup-fname-input {
  align-self: center;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-unit);
}

.signup-lname {
  color: var(--dl-color-gray-white);
  font-style: normal;
  font-weight: 600;
  margin-left: var(--dl-space-space-unit);
}

.signup-lname-input {
  align-self: center;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-unit);
}

.signup-discordUsername {
  color: var(--dl-color-gray-white);
  font-style: normal;
  font-weight: 600;
  margin-left: var(--dl-space-space-unit);
}

.signup-discordUsername-input {
  align-self: center;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-unit);
}

.signup-email {
  color: var(--dl-color-gray-white);
  font-style: normal;
  font-weight: 600;
  margin-left: var(--dl-space-space-unit);
}

.signup-email-input {
  align-self: center;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-unit);
}

.signup-password {
  color: var(--dl-color-gray-white);
  font-style: normal;
  font-weight: 600;
  margin-left: var(--dl-space-space-unit);
}

.signup-password-input {
  align-self: center;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
}

.signup-signup {
  color: var(--dl-color-gray-white);
  align-self: center;
  border-width: 0px;
  margin-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-primary-daikipurple);
}