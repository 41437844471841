.admin-home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-gray-pagebackground);
}

.admin-home-welcome-container {
  flex: 0 0 auto;
  display: flex;
  align-self: center;
  margin-top: var(--dl-space-space-fourunits);
  align-items: flex-start;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: column;
}

.admin-home-text {
  color: var(--dl-color-gray-900);
  font-size: 1.5em;
  align-self: center;
}

.admin-home-container1 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-threeunits);
  justify-content: center;
}

.admin-home-container2 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: flex-start;
  margin-left: var(--dl-space-space-fiveunits);
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-image: radial-gradient(circle at right top, rgb(94, 23, 235) 0.00%, rgb(56, 182, 255) 99.00%);
}

.admin-home-text01 {
  color: var(--dl-color-gray-white);
  align-self: center;
}

.admin-home-separator {
  flex: 0 0 auto;
  width: 100%;
  height: 0px;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-color: var(--dl-color-gray-white);
  border-style: solid;
  border-width: 1px;
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}

.admin-home-information {
  flex: 0 0 auto;
  width: 100%;
  height: var(--dl-size-size-small);
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
}

.admin-home-text04 {
  color: var(--dl-color-gray-white);
  align-self: center;
}

.admin-home-separator1 {
  flex: 0 0 auto;
  width: auto;
  height: var(--dl-size-size-xsmall);
  display: flex;
  margin-top: 16px;
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-gray-white);
  border-style: solid;
  border-width: 1px;
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 1px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}

.admin-home-text07 {
  color: var(--dl-color-gray-white);
  align-self: center;
  margin-left: var(--dl-space-space-halfunit);
}

.admin-home-separator2 {
  flex: 0 0 auto;
  width: auto;
  height: var(--dl-size-size-xsmall);
  display: flex;
  margin-top: 16px;
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-gray-white);
  border-style: solid;
  border-width: 1px;
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 1px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}

.admin-home-text10 {
  color: var(--dl-color-gray-white);
  align-self: center;
  margin-left: var(--dl-space-space-halfunit);
}

.admin-home-separator3 {
  flex: 0 0 auto;
  width: auto;
  height: var(--dl-size-size-xsmall);
  display: flex;
  margin-top: 16px;
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-gray-white);
  border-style: solid;
  border-width: 1px;
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 1px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}

.admin-home-icon {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
  align-self: center;
  margin-left: var(--dl-space-space-halfunit);
}

.admin-home-container3 {
  flex: 0 0 auto;
  width: 650px;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: center;
  margin-left: var(--dl-space-space-fiveunits);
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-image: radial-gradient(circle at left top, rgb(94, 23, 235) 0.00%, rgb(56, 182, 255) 99.00%);
}

.admin-home-text13 {
  color: var(--dl-color-gray-white);
  align-self: center;
}

.admin-home-separator4 {
  flex: 0 0 auto;
  width: 100%;
  height: 0px;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-color: var(--dl-color-gray-white);
  border-style: solid;
  border-width: 1px;
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}

.admin-home-text16 {
  color: var(--dl-color-gray-white);
  line-height: 1.75;
}

.admin-home-text17 {
  font-weight: 700;
}

.admin-home-text18 {
  font-weight: 700;
}

.admin-home-text20 {
  font-weight: 700;
  text-decoration: underline;
}

.admin-home-text38 {
  font-weight: 700;
}

.admin-home-text39 {
  font-weight: 700;
}

.admin-home-container4 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-threeunits);
  justify-content: center;
}

.admin-home-container5 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-image: radial-gradient(circle at left top, rgb(94, 23, 235) 0.00%, rgb(56, 182, 255) 99.00%);
}

.admin-home-text50 {
  color: var(--dl-color-gray-white);
  align-self: center;
}

.admin-home-separator5 {
  flex: 0 0 auto;
  width: 100%;
  height: 0px;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-color: var(--dl-color-gray-white);
  border-style: solid;
  border-width: 1px;
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}

.admin-home-text53 {
  color: var(--dl-color-gray-900);
  align-self: center;
  margin-top: var(--dl-space-space-halfunit);
}

.admin-home-text56 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-halfunit);
}

@media(max-width: 991px) {
  .admin-home-container1 {
    flex-direction: column;
  }

  .admin-home-container2 {
    align-self: center;
    margin-left: 0px;
  }

  .admin-home-container3 {
    width: 550px;
    margin-top: var(--dl-space-space-threeunits);
    margin-left: 0px;
  }
}

@media(max-width: 767px) {
  .admin-home-container1 {
    flex-direction: column;
  }

  .admin-home-container2 {
    align-self: center;
    margin-left: 0px;
  }

  .admin-home-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }

  .admin-home-separator1 {
    display: none;
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }

  .admin-home-separator2 {
    display: none;
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }

  .admin-home-separator3 {
    display: none;
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }

  .admin-home-container3 {
    width: auto;
    margin-top: var(--dl-space-space-twounits);
    margin-left: 0px;
  }

  .admin-home-separator4 {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }

  .admin-home-separator5 {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}

@media(max-width: 479px) {
  .admin-home-container1 {
    flex-direction: column;
  }

  .admin-home-container2 {
    align-self: center;
    margin-left: 0px;
  }

  .admin-home-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }

  .admin-home-text04 {
    font-size: 14px;
  }

  .admin-home-separator1 {
    display: none;
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }

  .admin-home-text07 {
    font-size: 14px;
  }

  .admin-home-separator2 {
    display: none;
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }

  .admin-home-text10 {
    font-size: 14px;
  }

  .admin-home-separator3 {
    display: none;
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }

  .admin-home-icon {
    width: 20px;
    height: 20px;
  }

  .admin-home-container3 {
    width: auto;
    margin-top: var(--dl-space-space-twounits);
    margin-left: 0px;
  }

  .admin-home-separator4 {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }

  .admin-home-separator5 {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}